import loadable from "@loadable/component";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import React, { useEffect, useGlobal } from "reactn";
import { useState, useRouter } from "react";
import call from "./call";
import axios from "axios";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
let Fallback = () => <div style={{ borderRadius: 16, height: "100vh" }} />;
const VersionEdit = loadable(() => import("./dashboard/playableVersionEdit"), {
	fallback: <Fallback />,
});
let App = () => {
  let [user, setUser] = useGlobal("user");
  const [showPage, setShowPage] = useState(false);

  useEffect(() => {
    getUser()
      .then((res) => {
        setUser({ ...user, ...res, isLoaded: true });
        document.cookie.split(";").forEach(function (cookie) {
          var name = cookie.split("=")[0];
          if (name === "token") window.token = cookie.split("=")[1];
        });
        //window.token = document.cookie.replace("token=", "");
        setShowPage(true);
      })
      .catch(() => {
        localStorage.clear();
        document.cookie.split(";").forEach(function (cookie) {
          var name = cookie.split("=")[0];
          document.cookie =
            name +
            "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;Domain=.playablefactory.com;";
        });
        document.cookie =
          "token=" +
          ";Expires=Thu, 01 Jan 1970 00:00:01 GMT;Domain=.playablefactory.com;";

        const redirectUrl = window.location.href;
        if (
          redirectUrl &&
          redirectUrl !== "https://playable-old.playablefactory.com/"
        ) {
          window.location.assign(
            `https://home.playablefactory.com/login?redirectUrl=${redirectUrl}`
          );
        } else {
          window.location.assign(`https://home.playablefactory.com/login`);
        }
      });
  }, []);

  useEffect(() => {
    if (user && user.isLoaded === false) {
      call("me").then((_user) =>
        setUser({ ...user, ..._user, isLoaded: true })
      );
    }
  }, [user, setUser]);

  const ExternalRedirect = ({ to }) => {
    useEffect(() => {
      window.location.href = to;
    }, [to]);

    return null;
  };

  return (
    <>
      {showPage ? (
        <Router>
          <Switch>
            <Route exact path="/">
              <ExternalRedirect to="https://home.playablefactory.com" />
            </Route>
            <Route path="/edit/:id">
              <VersionEdit />
            </Route>
          </Switch>
        </Router>
      ) : (
        <>
          <div className="flex justify-center items-center w-screen h-screen bg-[url('/gearbox-shell-background.svg')] bg-no-repeat bg-[length:100%_100%]">
            <img
              width={"150px"}
              height={"140px"}
              src={"/anim1_dark_gear.gif"}
              alt={"pf-logo-gif"}
            />
          </div>
        </>
      )}
    </>
  );

  async function getUser() {
    const response = await axios.post(
      "https://playable-old.playablefactory.com/api/me",
      {},
      {
        headers: {
          Authorization: document.cookie
            .split("; ")
            .find((row) => row.startsWith("token"))
            ?.split("=")[1],
          "Content-Type": "application/json",
        },
      }
    );
    return response.data;
  }
};
export default App;
