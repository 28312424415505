import { message } from "antd";
import jwtdecode from "jwt-decode";
import { setGlobal } from "reactn";
import SocketIO from "socket.io-client";

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

window.token = localStorage.getItem("token") || getCookie("token");

let search = window.location.search;

let _token = search.replace("?token=", "");
if (search.indexOf("?token") >= 0 && _token && _token.length > 1) {
  window.token = _token;
  let user = jwtdecode(window.token);
  setGlobal({
    user: { ...user, isLoaded: false },
  });
}

let host = "https://playable-old.playablefactory.com"; //.app olarak değiştirilmeli
const socketHost = "https://playable-old.playablefactory.com"; //.app olarak değiştirilmeli

let socket = SocketIO(socketHost);

setGlobal({
  user: window.token ? { token: window.token, isLoaded: false } : null,
});

let call = async (action, params, http) => {
  if (action === "uploadFile") {
    var formData = new FormData();
    formData.append("token", window.token);
    formData.append("file", params);
    let result = await fetch(host + "/api/uploadFile", {
      method: "POST",
      body: formData,
    });

    result = await result.json();

    return result;
  }
  if (action === "uploadFileCDN") {
    var formDataC = new FormData();
    formDataC.append("token", window.token);
    formDataC.append("file", params);
    let result = await fetch(host + "/api/uploadFileCDN", {
      method: "POST",
      body: formDataC,
    });

    result = await result.json();

    return result;
  } else {
    return await new Promise((res, rej) => {
      socket.emit(
        "call",
        {
          action,
          params,
          token: document.cookie
            .split("; ")
            .find((row) => row.startsWith("token"))
            ?.split("=")[1],
        },
        (result) => {
          if (result && result.error) {
            if (result.error && result?.error === "Session ended") {
              localStorage.clear();
              document.cookie.split(";").forEach(function (cookie) {
                var name = cookie.split("=")[0];
                document.cookie =
                  name +
                  "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;Domain=.playablefactory.com;";
                document.cookie =
                  "token=" +
                  ";Expires=Thu, 01 Jan 1970 00:00:01 GMT;Domain=.playablefactory.com;";
              });
              message.error("Session Ended");
              localStorage.removeItem("token");
              window.location.replace(
                "https://home.playablefactory.com/login"
              );
              return;
            }
            console.log(window.token);
            console.log(action, params, result);
            rej(new Error(result.error.msg));
          } else res(result);
        }
      );
    });
    // let {data: result} =  await Axios.post( "/api/call",{ action, params, token: window.token,})
    // if (result && result.error) {
    //   if(result.error && result?.error === 'Session ended')
    //   {
    //     message.error('Session Ended')
    //     localStorage.removeItem('token')
    //     window.location.reload()
    //     return;
    //   }
    //   throw new Error(result.error.msg);
    // } else return result;
  }
};

export default call;
